<template>
  <div class="plcManagementContainer">
    <table border="0" class="plcTable">
      <tr class="headerRow">
        <th valign="middle" class="descriptionCol">
          <div ref="readOnlyName" v-if="!isEditing">{{ plc.name }}</div>
          <text-input
            v-if="isEditing"
            :value="plcNameInput"
            @input="plcNameInput = $event"
          />
        </th>
        <th :title="isEditing ? dictionary('common.button.save')  : dictionary('common.name.edit')" valign="middle" class="actionIcon">
          <v-icon 
            v-if="!isEditing && !isInteracted"
            @click.stop="startEditing">
            edit
          </v-icon>
          <v-icon 
            v-else-if="!isInteracted"
            @click.stop="onSave">
            save
          </v-icon>
        </th>
        <th
          :title="dictionary('manage.plc.choose.type')" 
          valign="middle" 
          class="typeCol">
            <base-single-select
              :options="plcTypeOptions"
              option-value="id"
              option-label="label"
              :selected-value="plc.plcType"
              @selected="onChangePlcType"
              :disabled="isInteracted"/>
        </th>
        <th :title="dictionary('common.button.delete')" valign="middle" class="actionIcon">
          <div class="deletionContainer">
            <div v-if="pending">
              <BaseSpinner />
            </div>
            <v-icon
              v-if="!isEditing"
              @click.stop="askForDeleteConfirmation"
            >
              delete_outline
            </v-icon>
          </div>
        </th>
        <th :title="dictionary('manage.plc.download.configuration')" valign="middle" class="actionIcon">
          <v-icon
            v-if="!isEditing"
            @click.stop="askForDownloadConfirmation">
            save_alt
          </v-icon>
        </th>
        <th :title="dictionary('manage.plc.notes')" valign="middle" class="actionIcon">
          <icon-with-badge v-if="!isEditing" class="badge" material-icon="notes" :badge="plc.hasNotes ? 'i' : ''" badge-color="#fa0f36" @click="showNotes"/>      
        </th>
      </tr>
    </table>
    <modal-dialog 
      :show="showDownloadDialog"
      :header="'manage.plc.download.configuration' | translate"
      :button1-text="'manage.plc.download' | translate"
      :button2-text="'common.button.cancel' | translate"
      :button1-disabled="pending"
      :button2-disabled="pending"
      @button1Pressed="onDownloadConfirmed"
      @button2Pressed="showDownloadDialog = false"
    >
      <v-layout column>
        <v-flex class="confirmation-message">
          <div v-html="dictionary('management.plc.startDownload')"/>
          <div>{{ 'management.plc.startDownload.eccHint' | translate }}</div>
        </v-flex>
      </v-layout>
    </modal-dialog>
    <confirm-dialog
      :show="showDeleteConfirmation"
      :header="'partner.delete.sps' | translate"
      :text="'partner.delete.sps.warning' | translate"
      :confirm-button-text="'common.button.delete' | translate"
      :cancel-button-text="'common.button.cancel' | translate"
      :disabled="pending"
      @confirmed="onDeleteConfirmed"
      @cancelled="showDeleteConfirmation = false"
    />
    <notes/>
  </div>
</template>

<script lang="ts">
import BaseSpinner from '../../ui/BaseSpinner.vue'
import IconWithBadge from '../../ui/IconWithBadge.vue'

import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { InitializePlcInput } from '../../../../../eco-domain-store-modules/src/plcOperation/models'

import { DropdownOption } from '../../../../../shared-components/src'

import Notes from './notes/index.vue'

import { PlcType } from '@ecocoach/domain-store-modules/src/common'

import { PlcModel } from '@ecocoach/domain-store-modules/src/project/models'

const NoteUi = namespace('noteUi')
const ProjectManagementUi = namespace('projectManagementUi')
const PlcOperation = namespace('plcOperation')
const Resource = namespace('resource')

@Component({
  components: {
    BaseSpinner,
    IconWithBadge,
    Notes,
  },
})
export default class ProjectManagement extends Vue {
  @Prop({type: String, required: true }) public projectId: string
  @Prop({type: Object, required: true }) public plc: PlcModel
  
  public pending = false
  public isEditing: boolean = false
  public plcNameInput = ''
  public showDeleteConfirmation = false
  public showDownloadDialog = false

  @ProjectManagementUi.Action public setPlcType: (payload: { plcId: string, plcType: PlcType }) => Promise<void>
  @ProjectManagementUi.Action public updatePlc
  @ProjectManagementUi.Action public deletePlc: (id: string) => Promise<void>
  @ProjectManagementUi.Getter public isInteracted: boolean
  @ProjectManagementUi.Getter public plcTypeOptions: DropdownOption[]
  @NoteUi.Action public openNotesForPlc: (id: string) => Promise<void>
  @PlcOperation.Action public initializePlc: (payload: InitializePlcInput) => Promise<void>
  @Resource.Getter public dictionary

  public preparedDownloadPlc = ''
  public downloadDialogIsVisible = false

  public startEditing(){
    this.plcNameInput = this.plc.name,
    this.isEditing = true
  }

  public onSave() {
    if (this.plcNameInput.trim() === '') {
      this.$store.commit('wizard/setToastContent', this.dictionary('error.plc.name.empty'), {root: true})
      this.$store.commit('wizard/showToast', {root: true})
      return
    }
    this.pending = true
    this.updatePlc({
      ...this.plc,
      name: this.plcNameInput.trim(),
    } as PlcModel).finally(() => this.pending = false)
    this.isEditing = false
  }

  public onChangePlcType(plcType) {
    if(plcType === PlcType.UNDEFINED) {
      this.$store.commit('wizard/setToastContent', this.dictionary('error.plc.type.undefined'), {root: true})
      this.$store.commit('wizard/showToast', {root: true})  
      return
    }
    this.pending = true
    this.setPlcType({
      plcId: this.plc.id,
      plcType,
    }).finally(() => this.pending = false)
  }

  public askForDeleteConfirmation() {
    this.showDeleteConfirmation = true
  }

  public onDeleteConfirmed() {
    this.showDeleteConfirmation = false
    this.pending = true
    this.deletePlc(this.plc.id).finally(() => this.pending = false)
  }

  public askForDownloadConfirmation() {
    if(this.plc.plcType === PlcType.UNDEFINED) {
      this.$store.commit('wizard/setToastContent', this.dictionary('error.plc.type.undefined'), {root: true})
      this.$store.commit('wizard/showToast', {root: true})
      return
    }
    this.showDownloadDialog = true
  }

  public async showNotes() {
    await this.openNotesForPlc(this.plc.id)
  }

  public onDownloadConfirmed() {
    this.showDownloadDialog = false
    this.pending = true
    this.initializePlc({
      plcId: this.plc.id,
      fileName: `sdCardData-${this.plc.name}-${this.plc.plcType}.zip`,
    }).finally(() => this.pending = false)
  }
}
</script>
<style lang="scss" scoped>
.plcTable {
  // margin-bottom: -15px;
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-spacing: 0px;
  text-align: right;
  th {
    padding: 10px 10px;
    font-weight: normal;
    .typeCol {
      width: 25%;  
    }
    &.descriptionCol {
      font-weight: bold;
      justify-items: center;
      text-align: left;
      width: 55%;
      input {
        width: 100%;
        border-color: white;
        border-width: 1px;
        border-style: solid;
        padding-left: 5px;
        padding-right: 5px;
        overflow: hidden;
      }
    }
    &.actionIcon {
      font-weight: bold;
      width: 50px;
      cursor: pointer;
      .material-icons {
        max-width: 24px;
        color: white;
      }
      .badge {
        margin-top: 10px;
      }
    }
  }
  .deletionContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .headerRow {
      background: linear-gradient(to bottom, #20476e, #20476e); 
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #20476e), color-stop(0.6, #20476e));
    }
  .authDiv {
    display: flex;
    flex-direction: row;
    margin-bottom: -5px;
    > div {
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
.confirmation-message {
  margin-left: 10px;
}
.checkboxWithLabel {
  margin-left: -5px;
}

</style>